import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router)
const vueRouter = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'index',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/index')
  }, {
    path: '/build/:id',
    name: 'build',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/build')
  }, {
    path: '/view/:id',
    name: 'view',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/view')
  }, {
    path: '/UserLayout',
    name: 'UserLayout',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/UserLayout')
  },
  {
    path: '/RouteView',
    name: 'RouteView',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/RouteView')
  },
  {
    path: '/LoginSelectTenant',
    name: 'LoginSelectTenant',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/LoginSelectTenant')
  }
]
})
export default vueRouter;