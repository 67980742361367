import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
// import user from './modules/user'
// import permission from './modules/permission'
// import enhance from './modules/enhance'
// import online from './modules/online'
// import getters from './getters'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    app,
    // user,
    // permission,
    // enhance,
    // online
  },
  state: {
     //存放的键值对就是所要管理的状态
     username:''
  },
  mutations: {
    changeValue(state,newVal){
      state.username=newVal
  }
  },
  actions: {

  },
  // getters
})
