import { Loading } from 'element-ui';
import axios from 'axios';
import router from './router';
import store from './store';
axios.defaults.timeout = 10000;

//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
let loadingInstance = '';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  config.headers['X-Access-Token'] = localStorage.getItem('tokens');
  // loadingInstance = Loading.service({
  //   text: '拼命加载中',
  //   background: 'rgba(0,0,0,0.5)',  
  //   spinner: 'el-icon-loading'
  // });
  return config
}, error => {
  return Promise.reject(error)
});

// HTTPrequest拦截
axios.interceptors.response.use(config => {
  console.log(router.history.current.hash.slice(8))
  if(router.history.current.hash.slice(8)!=''){
    localStorage["tokens"] = router.history.current.hash.slice(8);
  }
  let username = localStorage.getItem("username")
  if(router.history.current.fullPath=="/"){
    if(username!="admin"){
      router.push({ path: "/UserLayout" })
    }
  }
  if (router.history.current.fullPath!='/UserLayout') {
    sessionStorage.setItem("routeUrl",router.history.current.fullPath);
  }
  if(!localStorage.getItem('tokens')){
    router.push({ path: "/UserLayout" })
  }else{
    if(router.history.current.name=='build'||router.history.current.name=='view'){
      if(!localStorage.getItem('tokens')){
        router.push({ path: "/UserLayout" })
      }else if(config.data.message == "Token失效，请重新登录"){
        router.push({ path: "/UserLayout" })
      }
    }
  }

  // loadingInstance.close();
  return config;
}, error => {
  return Promise.reject(new Error(error));
})

// axios.interceptors.response.use(function (response) { // ①10010 token过期（30天） ②10011 token无效
//   console.log(response,"1234")
//   loadingInstance.close();
//   if (response.data.code === 10010 || response.data.code === 10011) {

//     Storage.localRemove('token') // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）

//     router.replace({

//       path: '/' // 到登录页重新获取token

//     })

//   } else if (response.data.token) { // 判断token是否存在，如果存在说明需要更新token

//     Storage.localSet('token', response.data.token) // 覆盖原来的token(默认一天刷新一次)

//   }


// }, function (error) {

//   return Promise.reject(error)

// })

export default axios;